import React, { Component } from 'react';

import PaginationComponent from '../components/PaginationComponent';
import { compare, updateArrows } from '../utils/table';
import dateFormat from 'dateformat';
import { RESTOCKING_PAYMENT_STATUS } from '../utils/enums';
import { convertToMoney, getTextColorRestockingPayment } from '../utils/formats'

var config = process.env;

class TableRestockingComponent extends Component {

	constructor(){
        super();
        this.state = {
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            providers: [],
            loaded: false,
            selectedRestocking: []
		}
	}

	componentDidMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'restocking', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                var list = [];
                for (const [_, value] of Object.entries(data.data)) {
                    list.push(value);
                }

                // Aplicar el filtro inicial basado en `mode`
                const filteredData = this.props.mode === 1
                    ? list.filter(item => item.paymentStatus === 2)
                    : list;

                this.setState({
                    data: list,
                    filterData: filteredData.sort(compare("id")).reverse(),
                    loaded: true
                });
            }
        });
        this.getProviders();
    }

    componentDidUpdate(prevProps) {
        if (this.props.mode !== prevProps.mode) {
            const filteredData = this.props.mode === 1
                ? this.state.data.filter(item => item.paymentStatus === 2)
                : this.state.data;

            this.setState({
                filterData: filteredData.sort(compare("id")).reverse(),
                quantityShowRows: this.props.mode === 1 ? 10 : 50, // Cambia la cantidad de filas si es modo 1
            });
        }
    }
    

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay reposiciones</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    getProviders = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'provider/product', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    providers: data.data,
                });
            }
        });
    }

    getProviderById = (providerId) => {
        var name = "";
        this.state.providers.forEach(item => {
            if (providerId == item.providerId) {
                name = item.name
            }
        });
        return name;
    }

    createModals = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=0; i<this.state.data.length; i++) {
                var data = this.state.data[i];
                items.push(this.createModalView(data));
                if (data.paymentStatus == 2) {
                    items.push(this.createModalEdit(data));
                }
            }
            return (
                <div>{ items }</div>
            );
        }
    }

    createModalView = (data) => {
        return (
            <div className="modal fade" id={`modal-view-${data.id}`} role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Reposición</h4>
                        </div>
                        <div className="modal-body" style={{padding: '0'}}>
                            <ul style={{ marginTop: '20px' }}>
                                <li><b>Identificador:</b> { data.id }</li>
                                <li><b>Fecha:</b> { dateFormat(data.date, "dS mmmm, yyyy") }</li>
                                <li><b>Proveedor:</b> { this.getProviderById(data.provider) }</li>
                                <li><b>Estado del pago:</b> <span id={`item-status-li-${data.id}`} className={getTextColorRestockingPayment(data.paymentStatus)}>{ RESTOCKING_PAYMENT_STATUS[data.paymentStatus] }</span></li>
                                <li><b>Precio total:</b> ${ convertToMoney(data.totalPrice) }</li>
                                <li><b>Factura:</b> {data.invoiceId }</li>
                            </ul>
                            <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                <thead>
                                    <tr role="row">
                                        <th style={{ width: '20%' }}>Código</th>
                                        <th style={{ width: '30%' }}>Producto</th>
                                        <th style={{ width: '10%' }}>Cantidad</th>
                                        <th style={{ width: '20%' }}>Costo unitario</th>
                                        <th style={{ width: '20%' }}>Costo total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.items.map((element) => (
                                        <tr role="row" className="odd" key={element.code} style={{ background: data.items.indexOf(element) % 2 === 0 ? '#ffffff' : '#fcfcfc' }} >
                                            <td>{element.code}</td>
                                            <td>{element.name}</td>
                                            <td>{element.quantity}</td>
                                            <td>${ convertToMoney(element.unitPrice)}</td>
                                            <td>${ convertToMoney(element.quantity * element.unitPrice)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createModalEdit = (data) => {
        return (
            <div className="modal fade" id={`modal-edit-${data.id}`} role="dialog">
                <div className="modal-dialog" style={{marginTop: '10%'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Cambiar estado del pago</h4>
                        </div>
                        <div className="modal-body">
                            <p><b>Estado actual:</b> <span className={getTextColorRestockingPayment(data.paymentStatus)}>{ RESTOCKING_PAYMENT_STATUS[data.paymentStatus] }</span></p>
                            <button 
                                id="button-payed" 
                                type="button" 
                                data-dismiss="modal"
                                class="mb-2 btn btn-outline-info mr-2 col-md-2" 
                                style={{marginLeft: '0px', display: 'block', minWidth: '200px'}} 
                                onClick={() => this.onClickChangeStatus(data.id)}>
                                Cambiar estado a pagado
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleCheckboxChange = (id) => {
        this.setState((prevState) => {
            const { selectedRestocking } = prevState;
            let updatedSelectedRestocking;
    
            if (selectedRestocking.includes(id)) {
                updatedSelectedRestocking = selectedRestocking.filter(item => item !== id);
            } else {
                updatedSelectedRestocking = [...selectedRestocking, id];
            }
    
            return {
                selectedRestocking: updatedSelectedRestocking
            };
        }, () => {
            // Este callback se ejecuta después de que el estado se ha actualizado
            const totalSum = this.state.data
                .filter(item => this.state.selectedRestocking.includes(item.id))
                .reduce((sum, item) => sum + item.totalPrice, 0);
    
            this.props.setSelectedRestockingsAmmount(totalSum);
        });
    };


    onClickChangeStatus = (id) => {
        this.changeStatus(id)
        //SI ESTA OK ACTUALIZO EN LA VISTA
        let row = document.getElementById(`item-status-${id}`);
        row.innerHTML = RESTOCKING_PAYMENT_STATUS[parseInt(1)];
        row.classList = getTextColorRestockingPayment(parseInt(1));

        let li = document.getElementById(`item-status-li-${id}`);
        li.innerHTML = RESTOCKING_PAYMENT_STATUS[parseInt(1)];
        li.classList = getTextColorRestockingPayment(parseInt(1));

        document.getElementById(`button-status-${id}`).style.display = 'none';
          
    }    
    
    changeMasiveStatus = () => {
        this.state.selectedRestocking.forEach(id => {
            this.changeStatus(id)
        })
        window.location.href = "/restocking"
    }

    changeStatus = (id) => {
        var newStatus = 1; //Change to "Pago finalizado"
        var request = {
            method: 'PATCH',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: parseInt(id),
                status: parseInt(newStatus)
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'restocking/status', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                return 
            }
        });
    }
    
    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.id } style={{background: (index % 2 == 0) ? '#ffffff' : '#fcfcfc' }}>
                {this.props.mode >=  1 ? (<td>
                    <input
                        type="checkbox"
                        checked={this.state.selectedRestocking.includes(item.id)}
                        onChange={() => this.handleCheckboxChange(item.id)}
                    />
                </td>) : "" }
                <td tabindex="0" class="sorting_1">{ item.id }</td>
                <td>{ dateFormat(item.date, "dS mmmm, yyyy") }</td>
                <td>{ this.getProviderById(item.provider) }</td>
                <td id={`item-status-${item.id}`}>{ <span className={getTextColorRestockingPayment(item.paymentStatus)}>{ RESTOCKING_PAYMENT_STATUS[item.paymentStatus] }</span> }</td>                                
                <td>${ convertToMoney(item.totalPrice)}</td>                
                <td>{ item.invoiceId}</td>
                <td>
                    <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions">
                        <button 
                            data-toggle="modal"
                            data-target={`#modal-view-${item.id}`}
                            type="button" 
                            class="btn btn-white">
                            <i class="material-icons">remove_red_eye</i>
                        </button>
                        {this.props.mode === 0 && item.paymentStatus === 2 ? (
                            <button
                                id={`button-status-${item.id}`}
                                data-toggle="modal"
                                data-target={`#modal-edit-${item.id}`}
                                type="button"
                                className="btn btn-white">
                                <i className="material-icons"></i>
                            </button>
                        ) : null}
                    </div>
                </td>
            </tr>
        );
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = [];
        var data = this.state.data;
        for (var i=0; i < data.length; i++) {
            var paymentFormatted = RESTOCKING_PAYMENT_STATUS[data[i].paymentStatus];
            var providerFormatted = this.getProviderById(data[i].provider);
            var dateFormatted = dateFormat(data[i].date, "dS mmmm, yyyy");
            if (data[i].id.toString().toLowerCase().includes(input.toLowerCase()) ||
                dateFormatted.toString().toLowerCase().includes(input.toLowerCase()) ||
                providerFormatted.toString().toLowerCase().includes(input.toLowerCase()) ||
                data[i].totalPrice.toString().toLowerCase().includes(input.toLowerCase()) ||
                data[i].invoiceId?.toString().toLowerCase().includes(input.toLowerCase()) ||
                paymentFormatted.toString().toLowerCase().includes(input.toLowerCase())) {
                    newList.push( data[i] );
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {                
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            {this.props.mode >= 1 ? (<th className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}} aria-sort="ascending">Select</th>) : "" }
                            <th onClick={(e) => this.orderData(e, "code")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}} aria-sort="ascending">ID</th>
                            <th onClick={(e) => this.orderData(e, "name")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '25%'}}>Fecha</th>
                            <th onClick={(e) => this.orderData(e, "price")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '20%'}}>Proveedor</th>
                            <th onClick={(e) => this.orderData(e, "paymentStatus")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Estado del pago</th>
                            <th onClick={(e) => this.orderData(e, "finalPrice")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Costo total</th>
                            <th onClick={(e) => this.orderData(e, "invoiceId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Factura</th>
                            <th tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%', display: (this.props.role == 2) ? 'none' : 'table-cell'}}>Acciones</th>
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>
                
                { this.createModals() }
            
                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TableRestockingComponent;