import React, { Component } from 'react';
import { GENERAL_STATUS, TICKET_STATUS, TICKET_PAYMENT_METHOD, SHIPPING_TYPE, SHIPPING_STATUS, PRODUCT_TYPE } from '../utils/enums';
import { getTextColorClassShipping, getTextColorClassTicket, getTextColorClassGeneral, convertToMoney } from '../utils/formats'
import firebase from '@firebase/app';
import '@firebase/storage';
import dateFormat from 'dateformat';

var config = process.env;

class ModalPurchase extends Component {

	constructor(){
        super();
        this.state = {
            shippingStatus: undefined,
            shippingId: undefined,
            ticketId: undefined,
            shippingMethod: 2,
            link_ml: undefined,
            enabled: true,
            addedQuantity: {}
        }
        this.focusedInput = React.createRef();
	}

    componentDidUpdate(prevProps) {
        if (prevProps.purchaseId != this.props.purchaseId) {
            this.onLoadData(this.props.purchaseId)
        }
        if (this.focusedInput.current) {
            this.focusedInput.current.focus(); // Establecer el foco en el input si existe
        }
    }
    
    onLoadData = (purchaseId) => {
        this.removeDataModal();
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(`${config.REACT_APP_BASE_URL}${config.REACT_APP_VERSION}purchase/${purchaseId}`, request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) { 
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                var userAddress = undefined;
                if (data.data.purchase.user.address != undefined && data.data.purchase.user.address != '') {
                    userAddress = data.data.purchase.user.address + ", " + data.data.purchase.user.city;
                }
                var link = undefined;
                try {
                    link = JSON.parse(data.data.purchase.ticket.paymentData).initial_point
                    if (link == "") {
                        link = undefined;
                    }
                } catch (error) {}
    
                this.setState({
                    data: data.data,
                    shippingMethod: data.data.purchase.shipping.type,
                    userAddress: userAddress,
                    link_ml: link,
                    shippingStatus: data.data.purchase.shipping.status,
                    shippingId: data.data.purchase.shipping.shippingId,
                    ticketId: data.data.purchase.ticket.ticketId
                });

                if (this.props.status != 10)
                {
                    // Title
                    document.getElementById("modal-title").innerHTML = "Venta #" + data.data.purchase.purchaseId;
                    // General
                    document.getElementById("modal-purchase-general-date").innerHTML = dateFormat(data.data.purchase.date, "dS mmmm, yyyy - HH:MM");
                    document.getElementById("modal-purchase-general-status").innerHTML = GENERAL_STATUS[data.data.purchase.status];
                    document.getElementById("modal-purchase-general-status").classList = getTextColorClassGeneral(data.data.purchase.status);
                    // Client
                    document.getElementById("modal-purchase-client-cedula").innerHTML = data.data.purchase.user.cedula;
                    document.getElementById("modal-purchase-client-name").innerHTML = data.data.purchase.user.firstName + " " + data.data.purchase.user.lastName;
                    document.getElementById("modal-purchase-client-email").innerHTML = data.data.purchase.user.email;
                    document.getElementById("modal-purchase-client-phone").innerHTML = data.data.purchase.user.phone;
                    document.getElementById("modal-purchase-client-address").innerHTML = userAddress;
                    // Ticket                    
                    document.getElementById("modal-purchase-ticket-discount").innerHTML = data.data.purchase.discount_code;
                    if (data.data.purchase.discount_code == undefined || data.data.purchase.discount_code == "") {
                        document.getElementById('discount_code').style.display = 'none'
                    } else {
                        document.getElementById('discount_code').style.display = 'block'
                    }

                    document.getElementById("modal-purchase-ticket-status").innerHTML = TICKET_STATUS[data.data.purchase.ticket.status];
                    document.getElementById("modal-purchase-ticket-status").classList = getTextColorClassTicket(data.data.purchase.ticket.status);
                    document.getElementById("modal-purchase-ticket-payment-method").innerHTML = TICKET_PAYMENT_METHOD[data.data.purchase.ticket.paymentType];
                    if (data.data.purchase.ticket.status === 3) {
                        document.getElementById('button-payed').style.display = 'block'
                    } else {
                        document.getElementById('button-payed').style.display = 'none'
                    }
                    
                    document.getElementById("modal-purchase-ticket-price").innerHTML = "$ " + convertToMoney(data.data.purchase.ticket.totalPrice);
                    let cost = 0;
                    // Shipping
                    document.getElementById("modal-purchase-shipping-id").innerHTML = data.data.purchase.shipping.shippingId;
                    document.getElementById("modal-purchase-shipping-type").innerHTML = SHIPPING_TYPE[data.data.purchase.shipping.type];
                    document.getElementById("modal-purchase-shipping-provider").innerHTML = data.data.purchase.shipping.providerName;
                    document.getElementById("modal-purchase-shipping-status").innerHTML = SHIPPING_STATUS[data.data.purchase.shipping.status];
                    document.getElementById("modal-purchase-shipping-status").classList = getTextColorClassShipping(data.data.purchase.shipping.status);
                    document.getElementById("modal-purchase-shipping-time-preference").innerHTML = data.data.purchase.shipping.hourPrefference;
                    document.getElementById("modal-purchase-shipping-address").innerHTML = data.data.purchase.shipping.address;
                    document.getElementById("modal-purchase-shipping-city").innerHTML = data.data.purchase.shipping.city;
                    document.getElementById("modal-purchase-shipping-department").innerHTML = data.data.purchase.shipping.department;
                    document.getElementById("modal-purchase-shipping-cost").innerHTML = data.data.purchase.shipping.cost;
                    document.getElementById("modal-purchase-shipping-price").innerHTML = data.data.purchase.shipping.price;
                    document.getElementById("modal-purchase-shipping-postal-code").innerHTML = data.data.purchase.shipping.postalCode;
                    document.getElementById("modal-purchase-shipping-comments").innerHTML = data.data.purchase.shipping.comments;
                    if (data.data.purchase.shipping.type === 2) {
                        document.getElementById('button-retired').innerText = 'Cambiar estado a retirado'
                    } else {
                        document.getElementById('button-retired').innerText = 'Cambiar estado a entregado'
                    }
                    if (data.data.purchase.shipping.status === 6 || data.data.purchase.shipping.status === 4) {
                        document.getElementById('button-retired').style.display = 'block'
                    } else {
                        document.getElementById('button-retired').style.display = 'none'
                    }
                    // Products
                    var productsTable = document.getElementById("modal-purchase-products-table");
                    for (var i=1; i<=data.data.purchase.products.length; i++) {
                        var row = productsTable.insertRow();
                        
                        row.insertCell(0).innerHTML = data.data.purchase.products[i-1].prefix + data.data.purchase.products[i-1].code;
                        row.insertCell(1).innerHTML = data.data.purchase.products[i-1].name;
                        row.insertCell(2).innerHTML = PRODUCT_TYPE[data.data.purchase.products[i-1].productType];
                        row.insertCell(3).innerHTML = data.data.purchase.products[i-1].quantity;
                        row.insertCell(4).innerHTML = "$ " + convertToMoney(data.data.purchase.products[i-1].price);
                        row.insertCell(5).innerHTML = data.data.purchase.products[i-1].discount + " %";
                        let price = (data.data.purchase.products[i-1].price - (data.data.purchase.products[i-1].price*(data.data.purchase.products[i-1].discount/100))) * data.data.purchase.products[i-1].quantity;
                        row.insertCell(6).innerHTML = "$ " + convertToMoney(Math.round(price));

                        row.cells[0].style.padding = "8px";
                        row.cells[0].style.textAlign = "center";
                        row.cells[0].style.border = "1px solid #e1e5eb"
                        row.cells[1].style.padding = "8px";
                        row.cells[1].style.textAlign = "center";
                        row.cells[1].style.border = "1px solid #e1e5eb"
                        row.cells[2].style.padding = "8px";
                        row.cells[2].style.textAlign = "center";
                        row.cells[2].style.border = "1px solid #e1e5eb"
                        row.cells[3].style.padding = "8px";
                        row.cells[3].style.textAlign = "center";
                        row.cells[3].style.border = "1px solid #e1e5eb"
                        row.cells[4].style.padding = "8px";
                        row.cells[4].style.textAlign = "center";
                        row.cells[4].style.border = "1px solid #e1e5eb"
                        row.cells[5].style.padding = "8px";
                        row.cells[5].style.textAlign = "center";
                        row.cells[5].style.border = "1px solid #e1e5eb"
                        row.cells[6].style.padding = "8px";
                        row.cells[6].style.textAlign = "center";
                        row.cells[6].style.border = "1px solid #e1e5eb"


                        cost += data.data.purchase.products[i-1].cost * data.data.purchase.products[i-1].quantity
                    }
                    
                    //Ticket calculated

                    document.getElementById("modal-purchase-ticket-cost").innerHTML = "$ " + convertToMoney(cost);
                    document.getElementById("modal-purchase-ticket-profit").innerHTML = "$ " + convertToMoney(data.data.purchase.ticket.totalPrice - cost);

                    document.getElementById("modal-purchase-ticket-profitPercentage").innerHTML = (100*(data.data.purchase.ticket.totalPrice - cost)/data.data.purchase.ticket.totalPrice).toFixed(2) + " %" ;
                }
                else
                {             
                    // Title
                    document.getElementById("modal-title").innerHTML = "Preparar Venta #" + data.data.purchase.purchaseId;

                    var productsTable = document.getElementById("modal-preparation-products-table");
                    for (var i=1; i<=data.data.purchase.products.length; i++) {
                        let barCodeData = data.data.purchase.products[i-1].barCode
                        var row = productsTable.insertRow();
                        row.id = "row-"+barCodeData
                        row.insertCell(0).innerHTML = data.data.purchase.products[i-1].prefix + data.data.purchase.products[i-1].code;
                        row.insertCell(1).innerHTML = data.data.purchase.products[i-1].name;
                        let quantityCell = row.insertCell(2)
                        quantityCell.innerHTML = data.data.purchase.products[i-1].quantity;
                        quantityCell.id = "quantity-"+barCodeData
                        let barCodeCell = row.insertCell(3)
                        barCodeCell.innerHTML = this.state.addedQuantity[barCodeData] ? this.state.addedQuantity[barCodeData] : 0
                        barCodeCell.id = barCodeData

                        row.cells[0].style.padding = "8px";
                        row.cells[0].style.textAlign = "center";
                        row.cells[0].style.border = "1px solid #e1e5eb"
                        row.cells[1].style.padding = "8px";
                        row.cells[1].style.textAlign = "center";
                        row.cells[1].style.border = "1px solid #e1e5eb"
                        row.cells[2].style.padding = "8px";
                        row.cells[2].style.textAlign = "center";
                        row.cells[2].style.border = "1px solid #e1e5eb"
                        row.cells[3].style.padding = "8px";
                        row.cells[3].style.textAlign = "center";
                        row.cells[3].style.border = "1px solid #e1e5eb"
                    }
                }
            }

            this.loadFiles();
        });
    }

    removeDataModal = () => {
        if (this.props.status != 10)
        {
            // Title
            document.getElementById("modal-title").innerHTML = "Venta #";
            // General
            document.getElementById("modal-purchase-general-date").innerHTML = "";
            document.getElementById("modal-purchase-general-status").innerHTML = "";
            // Client
            document.getElementById("modal-purchase-client-name").innerHTML = "";
            document.getElementById("modal-purchase-client-email").innerHTML = "";
            document.getElementById("modal-purchase-client-phone").innerHTML = "";
            document.getElementById("modal-purchase-client-address").innerHTML = "";
            // Ticket
            document.getElementById("modal-purchase-ticket-price").innerHTML = "";
            document.getElementById("modal-purchase-ticket-status").innerHTML = "";
            document.getElementById("modal-purchase-ticket-payment-method").innerHTML = "";            
            document.getElementById("modal-purchase-ticket-cost").innerHTML = "";
            document.getElementById("modal-purchase-ticket-profitPercentage").innerHTML = "";
            document.getElementById("modal-purchase-ticket-profit").innerHTML = "";
            // Shipping
            document.getElementById("modal-purchase-shipping-type").innerHTML = "";
            document.getElementById("modal-purchase-shipping-provider").innerHTML = "";
            document.getElementById("modal-purchase-shipping-status").innerHTML = "";
            document.getElementById("modal-purchase-shipping-time-preference").innerHTML = "";
            document.getElementById("modal-purchase-shipping-address").innerHTML = "";
            document.getElementById("modal-purchase-shipping-cost").innerHTML = "";
            document.getElementById("modal-purchase-shipping-price").innerHTML = "";
            document.getElementById("modal-purchase-shipping-postal-code").innerHTML = "";
            // Delete prev rows
            var productsTable = document.getElementById("modal-purchase-products-table");
            var tableHeaderRowCount = 1;
            var rowCount = productsTable.rows.length;
            for (var i = tableHeaderRowCount; i < rowCount; i++) {
                productsTable.deleteRow(tableHeaderRowCount);
            }

            // Remove data from invoice
            this.clearFiles();

            //Remove local date
            this.setState({
                data: undefined,
                link_ml: undefined,
                enabled: true,
                file: undefined,
                fileName: undefined,                
                addedQuantity: {}
            });
        }
        else
        {
            // Delete prev rows
            var productsTable = document.getElementById("modal-preparation-products-table");
            var tableHeaderRowCount = 1;
            var rowCount = productsTable.rows.length;
            for (var i = tableHeaderRowCount; i < rowCount; i++) {
                productsTable.deleteRow(tableHeaderRowCount);
            }
        }
        
    }

    openTab = (index) => {
        for(var i=1; i<=6; i++) {
            var button = document.getElementById("tab-button-"+i);
            var content = document.getElementById("tab-"+i);
            if (index == i) {
                content.style.display = "block";
                button.classList.add("active-tab");
            } else {
                content.style.display = "none";
                button.classList.remove("active-tab");
            }
        }
    }

    renderGeneral = () => {
        return(
            <ul>
                <li style={{fontWeight: '700'}}>Fecha: <span id="modal-purchase-general-date" style={{fontWeight: '500'}}></span></li>
                <li style={{fontWeight: '700'}}>Estado: <span id="modal-purchase-general-status" style={{fontWeight: '500'}}></span></li>
            </ul>
        );
    }

    renderClient = () => {
        return(
            <ul>
                <li style={{fontWeight: '700'}}>Cédula: <span id="modal-purchase-client-cedula" style={{fontWeight: '500'}}></span></li>
                <li style={{fontWeight: '700'}}>Nombre: <span id="modal-purchase-client-name" style={{fontWeight: '500'}}></span></li>
                <li style={{fontWeight: '700'}}>Correo: <span id="modal-purchase-client-email" style={{fontWeight: '500'}}></span></li>
                <li style={{fontWeight: '700'}}>Teléfono: <span id="modal-purchase-client-phone" style={{fontWeight: '500'}}></span></li>
                <li style={{display:this.state.userAddress==undefined?'none':'list-item', fontWeight: '700'}}>Dirección: <span id="modal-purchase-client-address" style={{fontWeight: '500'}}></span></li>
            </ul>
        );
    }

    renderTicket = () => {
        return(
            <div>
                <ul>
                    <li id="discount_code" style={{fontWeight: '700'}}>Cupón de descuento: <span id="modal-purchase-ticket-discount" style={{fontWeight: '500'}}></span></li>
                    <li style={{fontWeight: '700'}}>Estado: <span id="modal-purchase-ticket-status" style={{fontWeight: '500'}}></span></li>
                    <li style={{fontWeight: '700'}}>Medio de pago: <span id="modal-purchase-ticket-payment-method" style={{fontWeight: '500'}}></span></li>
                    <li style={{display:this.state.link_ml==undefined?'none':'list-item', fontWeight: '700'}}>Mercado Pago: <span id="modal-purchase-ticket-link" style={{fontWeight: '500'}}><a href={this.state.link_ml} target="_blank">link</a></span></li>
                
                    
                    <li style={{fontWeight: '700'}}>Precio: <span id="modal-purchase-ticket-price" style={{fontWeight: '500'}}></span></li>
                    <li style={{fontWeight: '700'}}>Costo: <span id="modal-purchase-ticket-cost" style={{fontWeight: '500'}}></span></li>
                    <li style={{fontWeight: '700'}}>Ganancia: <span id="modal-purchase-ticket-profit" style={{fontWeight: '500'}}></span></li>
                    <li style={{fontWeight: '700'}}>Ganancia porcentual: <span id="modal-purchase-ticket-profitPercentage" style={{fontWeight: '500'}}></span></li>
                </ul>
                <button 
                    id="button-payed" 
                    type="button" 
                    class="mb-2 btn btn-outline-info mr-2 col-md-2" 
                    style={{marginLeft: '40px', display: 'block', minWidth: '200px'}} 
                    onClick={() => this.onClickShippingPayed()}>
                    Cambiar estado a pagado
                </button>
            </div>
        );
    }

    renderShipping = () => {
        return(
            <div>
                <ul>
                    <li style={{fontWeight: '700'}}>ID: <span id="modal-purchase-shipping-id" style={{fontWeight: '500'}}></span></li>
                    <li style={{fontWeight: '700'}}>Tipo de envío: <span id="modal-purchase-shipping-type" style={{fontWeight: '500'}}></span></li>
                    <li style={{fontWeight: '700'}}>Estado: <span id="modal-purchase-shipping-status" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Proveedor: <span id="modal-purchase-shipping-provider" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Horario de preferencia: <span id="modal-purchase-shipping-time-preference" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Departamento: <span id="modal-purchase-shipping-department" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Ciudad: <span id="modal-purchase-shipping-city" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Dirección: <span id="modal-purchase-shipping-address" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Código postal: <span id="modal-purchase-shipping-postal-code" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Comentarios: <span id="modal-purchase-shipping-comments" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Costo: <span id="modal-purchase-shipping-cost" style={{fontWeight: '500'}}></span></li>
                    <li style={{display: this.state.shippingMethod==2?'none':'list-item', fontWeight: '700'}}>Precio: <span id="modal-purchase-shipping-price" style={{fontWeight: '500'}}></span></li>
                </ul>
                <button 
                    id="button-retired" 
                    type="button" 
                    class="mb-2 btn btn-outline-info mr-2 col-md-2" 
                    style={{marginLeft: '40px', display: 'none', minWidth: '200px'}} 
                    onClick={() => this.onClickShippingRetired()}>
                    Retirado
                </button>
            </div>
        );
    }

    renderProduct = () => {
        return(
            <table id="modal-purchase-products-table" className="transaction-history d-none dataTable no-footer dtr-inline" role="grid" aria-describedby="DataTables_Table_0_info" >
                <thead>
                    <tr role="row">
                        <th rowspan="1" colspan="1" style={{width: '10%', border: '1px solid rgb(225, 229, 235)'}}>Código</th>
                        <th rowspan="1" colspan="1" style={{width: '25%'}}>Nombre</th>
                        <th rowspan="1" colspan="1" style={{width: '15%'}}>Tipo</th>
                        <th rowspan="1" colspan="1" style={{width: '10%'}}>Cantidad</th>
                        <th rowspan="1" colspan="1" style={{width: '10%'}}>Precio</th>
                        <th rowspan="1" colspan="1" style={{width: '10%'}}>Descuento</th>
                        <th rowspan="1" colspan="1" style={{width: '20%', border: '1px solid rgb(225, 229, 235)'}}>Precio final</th>
                    </tr>
                </thead>
            </table>
        );
    }

     // Función de debounce
     debounce = (func, delay) => {
        let timeoutId;
        return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    // Función para manejar el cambio en el input con debounce
    handleBarcodeChange = this.debounce((event) => {
        const barCode = event.target.value;
        if (barCode != null && barCode != undefined && barCode != 0 && barCode != "")
        {
            var addedQuantity = this.state.addedQuantity;
            var item = 0;
            let existsElement = false
            this.state.data.purchase.products.forEach(element => {
                if (element.barCode == barCode) {
                    existsElement = true
                    if (addedQuantity[barCode] != null)
                    {
                        let quantity = addedQuantity[barCode]
                        item = quantity +1
                    }
                    else{
                        item = 1
                    }                   
                    addedQuantity[barCode] = item;
                    this.setState({addedQuantity: addedQuantity});
                    document.getElementById(barCode).innerHTML = this.state.addedQuantity[barCode]
                    document.getElementById("barCode").value = "";                        

                    let productRow = document.getElementById("row-"+barCode)
                    if (item == document.getElementById("quantity-"+barCode).innerText)
                        productRow.className = "completedRow"
                    else
                        productRow.className = "badQuantity"
                }                
            })

            if (!existsElement)
            {
                console.log("notExists")
                document.getElementById("barCode").classList.add("barCodeError")
            }
            else
                document.getElementById("barCode").classList.remove("barCodeError")            
        } 
        else
            document.getElementById("barCode").classList.remove("barCodeError")   
    }, 150);

    
    renderPreparationProduct = () => {
        const containerStyle = {display: "flex", flexDirection: "column", alignItems: "center"}
        const barCodeStyle = {margin: "10px"}
        return(
            <div style={containerStyle} >                
                <table id="modal-preparation-products-table" className="transaction-history d-none dataTable no-footer dtr-inline" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th rowspan="1" colspan="1" style={{width: '25%', border: '1px solid rgb(225, 229, 235)'}}>Código</th>
                            <th rowspan="1" colspan="1" style={{width: '25%'}}>Nombre</th>
                            <th rowspan="1" colspan="1" style={{width: '25%'}}>Cantidad</th>
                            <th rowspan="1" colspan="1" style={{width: '25%'}}>Cantidad agregada</th>
                        </tr>
                    </thead>
                </table>
                <input autoComplete="off" className="col-lg-4 form-control" type="text" id="barCode" placeholder="Código de barras" ref={this.focusedInput} onChange={(e) => this.handleBarcodeChange(e)} style={barCodeStyle}/>           
            </div>
        );
    }

    clearFiles = () => {
        let filesElement = document.getElementById('files')
        let listFilesElement = document.getElementById('list-files')
        if (filesElement != null)
            filesElement.style.display = "none";
        if (listFilesElement != null)
            filesElement.innerHTML = "";
    }

    onClickShippingRetired = () => {
        var nextstatus = 5;
        if (this.state.shippingStatus == 4) {
            nextstatus = 8;
        }
        document.getElementById("button-retired").style.display = "none";
        var request = {
            method: 'PATCH',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                shippingId: parseInt(this.state.shippingId),
                status: parseInt(nextstatus)
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'shipping/status', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                window.location.href = "/purchase";
            }
        });
    }

    onClickShippingPayed = () => {
        document.getElementById("button-payed").style.display = "none";
        var request = {
            method: 'PATCH',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ticketId: parseInt(this.state.ticketId),
                status: parseInt(2)
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'ticket/status', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                window.location.href = "/purchase";
            }
        });
    }

    loadFiles = () => {
        const ref = firebase.storage().ref(`/purchase/${this.props.purchaseId}`);
        ref.listAll().then(dir => {
            if (dir.items.length > 0) {
                document.getElementById('files').style.display = "block";
            }
            dir.items.forEach(item => {                
                firebase.storage().ref(item.fullPath).getDownloadURL().then((url) => {
                    var a = document.createElement('a');
                    var linkText = document.createTextNode(item.name);
                    a.appendChild(linkText);
                    a.title = item.name;
                    a.href = url;
                    a.target = "_blank";
                    document.body.appendChild(a);

                    var ul = document.getElementById("list-files");
                    var li = document.createElement("li");
                    li.appendChild(a);
                    ul.appendChild(li);
                })
            });
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        try {
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({ 
                    file: reader.result,
                    fileName: files[0].name
                });
            };
            reader.readAsDataURL(files[0]);
        } catch (error) {}
        // Muestro boton
        document.getElementById("button-upload").style.display = "inline"
    }

    renderInvoice = () => {
        if (this.state.data != undefined) {
            return(            
                <div>
                    {this.state.data.purchase.ticket.invoiceId != null ? 
                        <div style={{marginTop:'10px'}}>
                            <h6>Identificador de la factura: {this.state.data.purchase.ticket.invoiceId}</h6>
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                onClick={() => window.open(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'invoice/file/' + this.state.data.purchase.ticket.invoiceId, '_blank')}>            
                                Ver factura
                            </button>
                        </div>    
                    :
                        <div style={{marginTop:'10px'}}>
                            <h6>Generar factura</h6>
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                onClick={() => window.location = `/addInvoice?id=${this.props.purchaseId}`}>
                                Generar factura
                            </button>     
                        </div>
                    }
                </div>
            );
        }
    }

    renderCancelPurchase = () => {
        if (this.state.data != undefined) {
            var generalStatus = this.state.data.purchase.status;
            if (generalStatus != 1 && generalStatus != 9) {
                return(
                    <div className="row">
                        <button id="button-confirm-delete" type="button" class="mb-2 btn btn-outline-danger mr-2 col-md-2" style={{marginLeft: '40px'}} onClick={() => this.onClickRemovePurchase()}>Cancelar</button>
                        <div class=" col-md-5">
                            <input type="text" class="form-control" id="input-confirm-delete" placeholder="Ingrese número de venta para confirmar" style={{display:'none'}}/>
                        </div>
                    </div>
                );
            } else if (generalStatus == 1) {
                return(
                    <div className="row">
                        <button id="button-confirm-change" type="button" class="mb-2 btn btn-outline-info mr-2 col-md-2" style={{marginLeft: '40px'}} onClick={() => this.onClickChangePurchase()}>Cambiar</button>
                    </div>
                );
            }
        }
    }

    onClickRemovePurchase = () => {
        document.getElementById("input-confirm-delete").style.display = "block";
        var value = document.getElementById("input-confirm-delete").value;
        if (value == this.props.purchaseId) {
            var request = {
                method: 'DELETE',
                headers: { 
                    'access-token': localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    purchaseId: parseInt(this.props.purchaseId)
                })
            };
            fetch(`${config.REACT_APP_BASE_URL}${config.REACT_APP_VERSION}purchase`, request)
            .then(results => { return results.json(); })
            .then(data => {  
                if (data.error > 0) {
                    alert(data.message);
                    if (data.error == 1002) {
                        window.location.href = localStorage.getItem('homePageURL');
                    }
                } else {
                    window.location.reload();
                }
            });
        } else if (value != null && value != undefined && value != "") {
            alert("No coincide el código")
        }
    }

    onClickChangePurchase = () => {        
        window.location.href = "/addPurchase?change="+this.props.purchaseId
    }

    onClickFinishPreparation = () => {
        let missingPreparation = []
        this.state.data.purchase.products.forEach(element => {
            if (element.quantity != this.state.addedQuantity[element.barCode]) {
                missingPreparation.push(element.barCode)
            }
        })
        if (missingPreparation.length == 0)
        {
            var request = {
                method: 'PATCH',
                headers: { 
                    'access-token': localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    purchaseId: this.props.purchaseId
                })
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase/status', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                    if (data.error == 1002) {
                        window.location.href = localStorage.getItem('homePageURL');
                    }
                } else {
                    window.location.reload();
                }
            });
        }
        else
        {
            missingPreparation.forEach(element => {
                document.getElementById("row-"+element).className = "badQuantity"
            })
        }        
    }

    clearPreparation  = () => {
        if(this.props.status == 10)
        {
            this.setState({addedQuantity: {}});
            this.state.data.purchase.products.forEach(element => {
                
                    document.getElementById(element.barCode).innerHTML = 0
                    document.getElementById("barCode").value = "";                        

                    let productRow = document.getElementById("row-"+element.barCode)
                    productRow.className = ""
            })
        }
    }

	render() {
        return (
            <div className="modal fade" id={`modal-view`} role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="modal-title" className="modal-title" style={{textAlign: "center"}}>Title</h4>
                        </div>
                        {this.props.status != 10 ? (
                        <div className="modal-body-2">
                            <div class="tab">
                                <button id="tab-button-1" class="tablinks active-tab" onClick={() => this.openTab(1)}>General</button>
                                <button id="tab-button-2" class="tablinks" onClick={() => this.openTab(2)}>Cliente</button>
                                <button id="tab-button-3" class="tablinks" onClick={() => this.openTab(3)}>Ticket</button>
                                <button id="tab-button-4" class="tablinks" onClick={() => this.openTab(4)}>Envío</button>
                                <button id="tab-button-5" class="tablinks" onClick={() => this.openTab(5)}>Productos</button>
                                <button id="tab-button-6" class="tablinks" onClick={() => this.openTab(6)}>Factura</button>
                            </div>
                            <div id="tab-1" class="tabcontent" style={{display: "block"}}>
                                <h3 style={{marginTop: '.75rem'}}>General</h3>
                                { this.renderGeneral() }
                                { this.renderCancelPurchase() }
                            </div>
                            <div id="tab-2" class="tabcontent">
                                <h3 style={{marginTop: '.75rem'}}>Cliente</h3>
                                { this.renderClient() }
                            </div>
                            <div id="tab-3" class="tabcontent">
                                <h3 style={{marginTop: '.75rem'}}>Ticket</h3>
                                { this.renderTicket() }
                            </div>
                            <div id="tab-4" class="tabcontent">
                                <h3 style={{marginTop: '.75rem'}}>Envío</h3>
                                { this.renderShipping() }
                            </div>
                            <div id="tab-5" class="tabcontent">
                                <h3 style={{marginTop: '.75rem'}}>Productos</h3>
                                { this.renderProduct() }
                            </div>
                            <div id="tab-6" class="tabcontent">
                                <h3 style={{marginTop: '.75rem'}}>Factura</h3>
                                { this.renderInvoice() }
                            </div>
                        </div>) :
                        (
                            this.renderPreparationProduct()
                        )}
                        <div className="modal-footer">
                        {this.props.status == 10 ? (
                            <button 
                                type="button" 
                                class="mb-2 btn btn-outline-info mr-2 col-md-2" 
                                style={{marginLeft: '40px',  minWidth: '200px'}} 
                                onClick={() => this.onClickFinishPreparation()}>
                                Confirmar preparación
                            </button>) : ""}
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal"
                                onClick={() => this.clearPreparation()}>
                                Cerrar
                            </button>                       
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalPurchase;