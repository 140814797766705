import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import TableMovementsComponent from '../components/TableMovementsComponent';
import PriceAccountComponent from '../components/PriceAccountComponent'
import { MOVEMENTS_CURRENCY } from '../utils/enums'
import DatePicker from 'react-datepicker';
import { validateSession } from  '../utils/functions';

var config = process.env;

class MovementsPage extends Component {

	constructor(){
        super();
        this.state = {
            loading: true,
            checked: false,
            role: 0,
            selectedMovementType: 0,
            selectCurrency: 1,
            selectAccountCurrency: 1,
            isExchangeVisible: false,
            accounts: [],
            spents: [],
            dateSelected: new Date(),
        }
        this.tableRef = React.createRef();
        this.priceRef = React.createRef();
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
        await this.fetchAccounts();
        await this.fetchSpendsType();
    }

    fetchAccounts = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'accounts', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({ 
                    loading: false,
                    accounts: data.data 
                });
            }
        });
    }

    fetchSpendsType = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'spents', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({ 
                    loading: false,
                    spents: data.data 
                });
            }
        });
    }

    createModalMovements = () => {
        return (
            <div className="modal fade" id="modal-movements" role="dialog">
                <div className="modal-dialog modal-lg">
                    { this.createModalContent() }
                </div>
            </div>
        );
    }

    createModalContent = () => {
        var selectedMovementType = this.state.selectedMovementType;
        if (selectedMovementType == 0) {
            return this.modalContentType();
        } else if (selectedMovementType == 2) {
            return this.modalContentSpent();
        } else if (selectedMovementType == 3) {
            return this.modalContentTransference();
        } else if (selectedMovementType == 4) {
            return this.modalContentDeposit();
        } 
    }

    modalContentType = () => {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" style={{textAlign: "center"}}>Tipo de movimiento</h4>
                </div>
                <div className="modal-body">
                    <label>Tipo de movimiento a realizar</label>
                    <select id="movementType" className="custom-select">
                        <option value="2">Gasto</option>
                        <option value="3">Transferencia</option>
                        <option value="4">Deposito</option>
                    </select>
                </div>
                <div className="modal-footer">
                    <button 
                        className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                        data-dismiss="modal">
                        Cancelar
                    </button>
                    <button 
                        className="mb-2 mr-1 btn btn-primary btn-sm" 
                        onClick={ () => this.updateCurentContent() }>
                        Confirmar
                    </button>                        
                </div>
            </div>
        )
    }

    modalContentSpent = () => {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" style={{textAlign: "center"}}>Ingrese el gasto</h4>
                </div>
                <div className="modal-body" style={{paddingTop:'20px', paddingBottom:'20px', paddingLeft:'0', paddingRight:'0', }}>
                    <div className="form-row mx-4">
                        <div className="form-group col-md-6">
                            <label>Fecha</label>
                            <DatePicker 
                                className="form-control" 
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.dateSelected} 
                                onChange={(date) => this.setState({ dateSelected: date })}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Concepto</label>
                            { this.renderSpentType("type-spent") }
                        </div>
                        <div className="form-group col-md-6">
                            <label>Cuenta a debitar</label>
                            { this.renderAccountSelect("account-spent") }
                        </div>
                    </div>
                    <div className="form-row mx-4">
                        <div className={this.state.isExchangeVisible ? "form-group col-md-3" : "form-group col-md-6"}>
                            <label>Moneda</label>
                            <select id="currency-spent" className="custom-select" onChange={ this.handleSelectCurrencyChange }>
                                <option value="1">Pesos</option>
                                <option value="2">Dolares</option>
                            </select>
                        </div>
                        <div className="form-group col-md-3" style={{ display: this.state.isExchangeVisible ? 'block' : 'none' }}>
                            <label>Tipo de cambio</label>
                            <input type="number" className="form-control" id="exchange-spent" placeholder="Cambio" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Monto</label>
                            <input type="number" className="form-control" id="amount-spent" placeholder="Monto" />
                        </div>
                    </div>

                    <p id="message-spent" style={{display: 'none', paddingBottom: '0', marginBottom: '0', textAlign: 'center', color: '#ce1c1c'}}></p>
                </div>
                <div className="modal-footer">
                    <button
                        className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                        data-dismiss="modal"
                        onClick={ () => this.resetCurentContent() }>
                        Cancelar
                    </button>
                    <button
                        className="mb-2 mr-1 btn btn-primary btn-sm"
                        onClick={ () => this.onClickSpent() }>
                        Confirmar
                    </button>
                    <button
                        id="close-modal"
                        data-dismiss="modal"
                        style={{display: 'none'}}>
                    </button>                    
                </div>
            </div>
        )
    }

    modalContentTransference = () => {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" style={{textAlign: "center"}}>Ingrese la transferencia</h4>
                </div>
                <div className="modal-body" style={{paddingTop:'20px', paddingBottom:'0', paddingLeft:'0', paddingRight:'0', }}>
                    <div className="form-row mx-4">
                        <div className="form-group col-md-6">
                            <label>Fecha</label>
                            <DatePicker 
                                className="form-control" 
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.dateSelected} 
                                onChange={(date) => this.setState({ dateSelected: date })} 
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-body" style={{paddingTop:'0', paddingBottom:'20px', paddingLeft:'0', paddingRight:'0', }}>
                    <div className="form-row mx-4">
                        <div className="form-group col-md-6">
                            <label>Cuenta origen</label>
                            { this.renderAccountSelect("transference-origin") }
                        </div>
                        <div className="form-group col-md-6">
                            <label>Cuenta destino</label>
                            { this.renderAccountDestinationSelect("transference-destination") }
                        </div>
                        <div className="form-group col-md-3" style={{ display: this.state.isExchangeVisible ? 'block' : 'none' }}>
                            <label>Moneda de la transacción</label>
                            <select id="transference-currency" className="custom-select">
                                <option value="1">{ MOVEMENTS_CURRENCY[1] }</option>
                                <option value="2">{ MOVEMENTS_CURRENCY[2] }</option>
                            </select>
                        </div>
                        <div className="form-group col-md-3" style={{ display: this.state.isExchangeVisible ? 'block' : 'none' }}>
                            <label>Tipo de cambio</label>
                            <input type="number" className="form-control" id="exchange-transference" placeholder="Cambio" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Monto</label>
                            <input type="number" className="form-control" id="amount-transference" placeholder="Monto" />
                        </div>
                    </div>

                    <p id="message-transference" style={{display: 'none', paddingBottom: '0', marginBottom: '0', textAlign: 'center', color: '#ce1c1c'}}></p>
                </div>
                <div className="modal-footer">
                    <button 
                        className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                        data-dismiss="modal"
                        onClick={ () => this.resetCurentContent() }>
                        Cancelar
                    </button>
                    <button 
                        className="mb-2 mr-1 btn btn-primary btn-sm" 
                        onClick={ () => this.onClickTransference() }>
                        Confirmar
                    </button>
                    <button
                        id="close-modal"
                        data-dismiss="modal"
                        style={{display: 'none'}}>
                    </button>                    
                </div>
            </div>
        )
    }

    modalContentDeposit = () => {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title" style={{textAlign: "center"}}>Ingrese el Deposito</h4>
                </div>
                <div className="modal-body" style={{paddingTop:'20px', paddingBottom:'20px', paddingLeft:'0', paddingRight:'0', }}>
                    <div className="form-row mx-4">
                        <div className="form-group col-md-6">
                            <label>Fecha</label>
                            <DatePicker 
                                className="form-control" 
                                dateFormat="dd/MM/yyyy"
                                selected={this.state.dateSelected} 
                                onChange={(date) => this.setState({ dateSelected: date })} 
                            />
                        </div>                        
                        <div className="form-group col-md-6">
                            <label>Cuenta a acreditar</label>
                            { this.renderAccountSelect("account-deposit") }
                        </div>
                    </div>
                    <div className="form-row mx-4">
                        <div className={this.state.isExchangeVisible ? "form-group col-md-3" : "form-group col-md-6"}>
                            <label>Moneda</label>
                            <select id="currency-deposit" className="custom-select" onChange={ this.handleSelectCurrencyChange }>
                                <option value="1">Pesos</option>
                                <option value="2">Dolares</option>
                            </select>
                        </div>
                        <div className="form-group col-md-3" style={{ display: this.state.isExchangeVisible ? 'block' : 'none' }}>
                            <label>Tipo de cambio</label>
                            <input type="number" className="form-control" id="exchange-deposit" placeholder="Cambio" />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Monto</label>
                            <input type="number" className="form-control" id="amount-deposit" placeholder="Monto" />
                        </div>
                    </div>
                    <p id="message-deposit" style={{display: 'none', paddingBottom: '0', marginBottom: '0', textAlign: 'center', color: '#ce1c1c'}}></p>
                </div>
                <div className="modal-footer">
                    <button 
                        className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                        data-dismiss="modal"
                        onClick={ () => this.resetCurentContent() }>
                        Cancelar
                    </button>
                    <button 
                        className="mb-2 mr-1 btn btn-primary btn-sm" 
                        onClick={ () => this.onClickDeposit() }>
                        Confirmar
                    </button>
                    <button
                        id="close-modal"
                        data-dismiss="modal"
                        style={{display: 'none'}}>
                    </button>
                </div>
            </div>
        )
    }

    renderAccountSelect = (id) => {
        var items = [];
        this.state.accounts.forEach(element => {
            items.push(<option value={ `${element.id}-${element.currency}` }>{ `${element.name} - ${ MOVEMENTS_CURRENCY[element.currency] }` }</option>)
        });
        return(
            <select id={id} className="custom-select" onChange={this.handleSelectAccountCurrencyChange}> { items } </select>
        );
    }

    renderAccountDestinationSelect = (id) => {
        var items = [];
        this.state.accounts.forEach(element => {
            items.push(<option value={ `${element.id}-${element.currency}` }>{ `${element.name} - ${ MOVEMENTS_CURRENCY[element.currency] }` }</option>)
        });
        return(
            <select id={id} className="custom-select" onChange={this.handleSelectAccountDestinationCurrencyChange}> { items } </select>
        );
    }

    renderSpentType = (id) => {
        var items = [];
        this.state.spents.forEach(element => {
            items.push(<option value={ `${element.id}` }>{ `${element.concept}` }</option>)
        });
        return(
            <select id={id} className="custom-select"> { items } </select>
        );
    }

    handleSelectAccountCurrencyChange = (event) => {
        const accountCurrency = event.target.value.split('-')[1];
        var isVisible = false;
        if (this.state.selectCurrency != accountCurrency) {
            isVisible = true;
        }
        this.setState({ isExchangeVisible: isVisible, selectAccountCurrency: accountCurrency });
    }

    handleSelectAccountDestinationCurrencyChange = (event) => {
        const currency = event.target.value.split('-')[1];
        var isVisible = false;
        if (currency != this.state.selectAccountCurrency) {
            isVisible = true;
        }
        this.setState({ isExchangeVisible: isVisible, selectCurrency: currency });
    }

    handleSelectCurrencyChange = (event) => {
        const currency = event.target.value;
        var isVisible = false;
        if (currency != this.state.selectAccountCurrency) {
            isVisible = true;
        }
        this.setState({ isExchangeVisible: isVisible, selectCurrency: currency });
    }

    updateCurentContent = () => {
        var value = document.getElementById('movementType').value;
        this.setState({ selectedMovementType: value })
    }

    resetCurentContent = () => {
        this.setState({ 
            selectedMovementType: 0,
            selectCurrency: 1,
            selectAccountCurrency: 1,
            isExchangeVisible: false,
            dateSelected: new Date(),
        });
    }

    onClickSpent = () => {
        var date = this.state.dateSelected;
        var concept = document.getElementById("type-spent").value;
        var account = document.getElementById("account-spent").value.split('-')[0];
        var currencyAccount = document.getElementById("account-spent").value.split('-')[1];
        var currency = document.getElementById("currency-spent").value;
        var amount = document.getElementById("amount-spent").value;
        var exchange = document.getElementById("exchange-spent").value;

        if (this.isEmpty(date) || this.isEmpty(amount) || (currencyAccount != currency && this.isEmpty(exchange))) {
            document.getElementById("message-spent").innerHTML = "* Complete todos los campos"
            document.getElementById("message-spent").style.display = "block"
            return;
        } else {
            document.getElementById("message-spent").innerHTML = ""
            document.getElementById("message-spent").style.display = "none";
        }

        var exchangeRate = 1;
        if (currencyAccount != currency) {
            var exchangeRate = exchange;
        }

        //Send to backend
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                date: date,
                concept: parseInt(concept),
                account: parseInt(account),
                currency: parseInt(currency),
                amount: parseInt(amount),
                exchange: parseInt(exchangeRate)
            })
        };

        this.resetCurentContent(); 
        document.getElementById("close-modal").click();

        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'movements/spent', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.tableRef.current.fetchData();
                this.priceRef.current.fetchData();
            }
        });
    }

    onClickTransference = () => {
        var date = this.state.dateSelected;
        var accountOrigin = document.getElementById("transference-origin").value.split('-')[0];
        var currencyOrigin = document.getElementById("transference-origin").value.split('-')[1];
        var accountDestination = document.getElementById("transference-destination").value.split('-')[0];
        var currencyDestination = document.getElementById("transference-destination").value.split('-')[1];
        var amount = document.getElementById("amount-transference").value;
        var exchange = document.getElementById("exchange-transference").value;
        var currencyTransaction = document.getElementById("transference-currency").value;
        
        if (accountOrigin == accountDestination) {
            document.getElementById("message-transference").innerHTML = "* Las cuentas deben ser diferentes"
            document.getElementById("message-transference").style.display = "block"
            return;
        } else if (this.isEmpty(date) || this.isEmpty(amount) || (currencyOrigin != currencyDestination && this.isEmpty(exchange))) {
            document.getElementById("message-transference").innerHTML = "* Complete todos los campos"
            document.getElementById("message-transference").style.display = "block"
            return;
        } else {
            document.getElementById("message-transference").innerHTML = ""
            document.getElementById("message-transference").style.display = "none";
        }

        var exchangeRate = 1;
        if (currencyOrigin != currencyDestination) {
            var exchangeRate = exchange;
        }

        //Send to backend
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                date: date,
                currencyTransaction: parseInt(currencyTransaction),
                accountOrigin: parseInt(accountOrigin),
                currencyOrigin: parseInt(currencyOrigin),
                accountDestination: parseInt(accountDestination),
                currencyDestination: parseInt(currencyDestination),
                amount: parseInt(amount),
                exchange: parseInt(exchangeRate)
            })
        };

        this.resetCurentContent();
        document.getElementById("close-modal").click();

        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'movements/transference', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.tableRef.current.fetchData();
                this.priceRef.current.fetchData();
            }
        });
    }

    onClickDeposit = () => {
        var date = this.state.dateSelected;
        var account = document.getElementById("account-deposit").value.split('-')[0];
        var currencyAccount = document.getElementById("account-deposit").value.split('-')[1];
        var currency = document.getElementById("currency-deposit").value;
        var amount = document.getElementById("amount-deposit").value;
        var exchange = document.getElementById("exchange-deposit").value;

        if (this.isEmpty(date) || this.isEmpty(amount) || (currencyAccount != currency && this.isEmpty(exchange))) {
            document.getElementById("message-deposit").innerHTML = "* Complete todos los campos"
            document.getElementById("message-deposit").style.display = "block"
            return;
        } else {
            document.getElementById("message-deposit").innerHTML = ""
            document.getElementById("message-deposit").style.display = "none";
        }

        var exchangeRate = 1;
        if (currencyAccount != currency) {
            var exchangeRate = exchange;
        }

        //Send to backend
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                date: date,
                account: parseInt(account),
                currency: parseInt(currency),
                amount: parseInt(amount),
                exchange: parseInt(exchangeRate)
            })
        };

        this.resetCurentContent();
        document.getElementById("close-modal").click();

        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'movements/deposit', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.tableRef.current.fetchData();
                this.priceRef.current.fetchData();
            }
        });
    }

    isEmpty = (value) => {
        return(value == null || value == undefined || value == "" );
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={14} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '80px'}}>
                                <HeaderComponent title="Dashboard" subtitle="Movimientos"/>

                                <PriceAccountComponent ref={this.priceRef}/>

                                <TableMovementsComponent ref={this.tableRef} role={this.state.role}/>
                            </div>
                        </main>
                    </div>

                    { this.createModalMovements() }

                    <button id="dismiss-modal" data-dismiss="modal" style={{display: 'none'}}></button>      

                    <div data-toggle="modal" data-target="#modal-movements" class="color-switcher-toggle animated pulse infinite" style={{display: (this.state.role == 2) ? 'none' : 'block'}}>
                        <i class="material-icons">add</i>
                    </div>
                </div>
            );
        }
    }
}

export default MovementsPage;