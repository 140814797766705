import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import RestockingProductComponent from '../components/RestockingProductComponent';
import { RESTOCKING_PAYMENT_STATUS } from '../utils/enums';
import { validateSession } from  '../utils/functions';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

var config = process.env;

class AddRestockingPage extends Component {

	constructor(){
        super();
        this.state = {
            disabled: false,
            checked: false,
            dateSelected: null,
            role: 0,
            products: {},
            providers: [],
            invoice: null
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
        await this.getProviders();
    }

    getProviders = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'provider/product', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    providers: data.data,
                });
            }
        });
    }

    handlerSelectProduct = (value) => {
        this.setState({products: value});
    }

    save = () => {
        if (Object.keys(this.state.products).length == 0) {
            alert("Agregue al menos 1 producto");
            return;
        }
        var provider = document.getElementById('provider').value;
        if (provider == "999")
        {
            alert("Seleccione proveedor");
            return;
        }       
        if (this.state.dateSelected == null)
        {
            alert("Ingresar fecha de la factura");
            return;
        }       
        if (this.state.invoice == null)
        {
            alert("Ingresar numero de factura");
            return;
        }   
        var paymentStatus = document.getElementById('paymentStatus').value;

        //Convert to dic to list
        var products = [];
        for (const [key, value] of Object.entries(this.state.products)) {
            products.push(value);
        }
        
        this.setState({ disabled: true });
        var token = localStorage.getItem('token');
        var request = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'access-token': token
            },
            body: JSON.stringify({
                date: this.state.dateSelected,
                provider: parseInt(provider),
                products: products,
                paymentStatus: parseInt(paymentStatus),
                invoice: this.state.invoice
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'restocking', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                window.location.href = "/restocking";
            }
        });
    }

    renderProviders = () => {
        var items = [];
        this.state.providers.forEach(element => {
            items.push(
                <option value={element.providerId}>{ element.name }</option>
            )
        });
        return (
            <select id="provider" className="custom-select">
                <option value="999">Seleccione proveedor</option>
                { items }
            </select>
        );
    }

    onChangeInvoice = (e) => {
        console.log(e.target.value)
        this.setState({ invoice: e.target.value });
    }
    renderGenericData = () => {
        return(
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom">
                        <h6 className="m-0">Datos generales</h6>
                    </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item p-3">
                    <div className="row">
                        <div className="col">
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label>Proveedor</label>
                                    { this.renderProviders() }
                                </div>
                                <div className="form-group col-md-3">
                                    <label for="phone">Fecha</label>
                                    <DatePicker 
                                        className="form-control" 
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.dateSelected} 
                                        onChange={(date) => this.setState({ dateSelected: date })}                                         
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Pago</label>
                                    <select id="paymentStatus" className="custom-select" defaultValue={2}>
                                        <option value="1">{ RESTOCKING_PAYMENT_STATUS[1] }</option>
                                        <option value="2">{ RESTOCKING_PAYMENT_STATUS[2] }</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Numero de factura</label>
                                    <input autoComplete="off" className="form-control" type="text" id="invoice" placeholder="Numero de factura" onChange={(e) => this.onChangeInvoice(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
            </div>
        );
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={13} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '80px'}}>
                                <HeaderComponent title="Dashboard" subtitle="Nueva reposición de stock"/>
                                { this.renderGenericData() }
                                <RestockingProductComponent onChangeData={ this.handlerSelectProduct }/>
                                <button disabled={this.state.disabled} type="button" class="mb-2 btn btn-primary" style={{float: "right", width: "200px", marginRight: "15px"}} onClick={() => this.save()}>Cargar</button>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddRestockingPage;