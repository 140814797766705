import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import { validateSession } from  '../utils/functions';
import '@firebase/storage';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PickList } from 'primereact/picklist';
import { ProgressSpinner } from 'primereact/progressspinner';

var config = process.env;

class LabelAssignPage extends Component {

	constructor(){
        super();
        this.state = {
            source: [],
            target: [],
            labelInfo: "",
            initialSelection: [],
            label_id: null,
            loaded: false
        }
        this.onChange = this.onChange.bind(this);
	}

    async componentDidMount() {
        let role = await validateSession();
        this.setState({ checked: true, role: role });
        const search = this.props.location.search;
        const queryParams = new URLSearchParams(search);
        const label_id = queryParams.get('id');
        this.setState({ label_id: Number(label_id) });
        await this.fetchLabel(label_id);
    }

    async fetchProducts() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product/all', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    source: data.data.filter(item1 => 
                        !this.state.target.some(item2 => item2.code === item1.code)
                      ),
                      loaded: true
                });
            }
        });
    }

    async fetchLabel(label_id) {
        var request = {
            method: 'GET',
            headers: {
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'label/' + label_id, request)
        .then(results => results.json())
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    target: data.data.labeledProducts,
                    labelInfo: data.data.labelInfo
                }, () => {
                    // Después de actualizar el estado con target, llama a fetchProducts
                    this.fetchProducts();
                });
            }
        });
    }

    setDate = (value) => {
        this.setState({date: value})
    }
    
    onChange = (event) => {
        this.setState({source: event.source, target: event.target});
    };

    onClickSave = () => {       
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                label_id: this.state.label_id,
                product_codes: this.state.target.map(obj => obj.code),
            })
        };
        console.log(request)
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'label/labeledProducts', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                this.setState({ disabled: false });
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                window.location.href = "/labels";
            }
        });
    }

    itemTemplate = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                {/* <img className="w-4rem shadow-2 flex-shrink-0 border-round" src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`} alt={item.name} /> */}
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.name}</span>
                    {/* <div className="flex align-items-center gap-2">
                        <i className="pi pi-tag text-sm"></i>
                        <span>{item.category}</span>
                    </div> */}
                </div>
                {/* <span className="font-bold text-900">${item.price}</span> */}
            </div>
        );
    };

    
	
    render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={15} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />                            
                            <div className="main-content-container container-fluid px-4 mb-4 pt-4">
                                <h3 class="page-title">{this.state.loaded ? "Asignación de productos a la etiqueta: " + this.state.labelInfo.name : ""}</h3>
                                <div>
                                    {this.state.loaded ? (
                                        <>
                                            <PickList dataKey="code" sourceSelection={this.state.sourceSelection} source={this.state.source} target={this.state.target} onChange={this.onChange} itemTemplate={this.itemTemplate} breakpoint="1280px"
                                                autoOptionFocus={false} filter filterBy="name" showTargetControls={false} showSourceControls={false} sourceHeader="Disponibles" targetHeader="Seleccionados" sourceStyle={{ maxHeight: 'calc(100% - 190px)' }} targetStyle={{ maxHeight: 'calc(100% - 190px)' }} style={{height: "calc(100vh - 180px)"}}/>
                                            <button onClick={() => this.onClickSave()} 
                                                    type="button" 
                                                    className="mb-2 btn btn-primary mr-2" 
                                                    style={{float: 'right'}} 
                                                    disabled={this.state.disabled}>
                                                    Guardar
                                            </button>
                                        </>
                                    ) : (
                                        <div className='centeredSpinner'>
                                            <ProgressSpinner/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }    
}

export default LabelAssignPage;