import React, { Component } from 'react';

import { ROL_TYPE } from '../utils/enums'

var config = process.env;
var sha1 = require('sha1');

class LoginPage extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            role: 0,
            disabled: false,
            email: this.getCookie('email')
        }
    }
    
    componentWillMount() {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/checkSessionAdmin', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error == 0 && data.data.isValidToken) {
                window.location.href = "/purchase";
            } else {
                this.setState({ checked: true, role: data.data.role });
            }
        });
    }

    onClickLogin = () => {
        var hasError = false;
        var email = document.getElementById("email").value;
        var password = document.getElementById("password").value;
        var remember = document.getElementById("checkbox").checked;
        var passwordEncrypted = sha1(password);

        if (email == undefined || email == '') {
            hasError = true;
            document.getElementById("email").className = "form-control is-invalid"
        } else {
            document.getElementById("email").className = "form-control"
        }
        if (password == undefined || password == '') {
            hasError = true;
            document.getElementById("password").className = "form-control is-invalid"
        } else {
            document.getElementById("password").className = "form-control"
        }
        if (hasError) {
            return;
        }
        
        if (remember) {
            this.setCookie("email", email);
        } else {
            this.setCookie("email", "");
        }

        var request = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: passwordEncrypted,
            isUser: false
          })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/login', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                document.getElementById("password").className = "form-control is-invalid"
                this.setState({ disabled: false });
            } else {
                // Save user in local storage and redirect
                localStorage.setItem('token', data.data.token);
                localStorage.setItem('email', data.data.email);
                localStorage.setItem('firstName', data.data.user.firstName);
                localStorage.setItem('lastName', data.data.user.lastName);
                localStorage.setItem('image', '');
                if (data.data.role == ROL_TYPE.ROLE_FINANCE) {
                    localStorage.setItem('homePageURL', '/invoices');
                    window.location.href = "/invoices";
                } else {
                    localStorage.setItem('homePageURL', '/purchase');
                    window.location.href = "/purchase";
                }
            }
        });
    }

    setCookie = (cname, cvalue) => {
        var d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (
                <div className="main-content-container container-fluid px-4 my-auto" >
                    <div className="row no-gutters">
                        <div className="col-lg-3 col-md-5 auth-form mx-auto my-auto">
                            <div className="card" style={{marginTop: '150px'}}>
                                <div className="card-body">
                                    <img className="d-table mx-auto mb-3" src="./images/logo.png" style={{maxWidth: '9rem'}} />
                                    <div>
                                        <div className="form-group">
                                            <label for="email">Correo</label>
                                            <input type="email" className="form-control" id="email" placeholder="Ingrese su correo" defaultValue={this.state.email}/>
                                        </div>
                                        <div className="form-group">
                                            <label for="password">Contraseña</label>
                                            <input type="password" className="form-control" id="password" placeholder="Ingrese su contraseña" />
                                        </div>
                                        <div className="form-group mb-3 d-table mx-auto">
                                            <div className="custom-control custom-checkbox mb-1">
                                                <input type="checkbox" className="custom-control-input" id="checkbox" defaultChecked={this.state.email.length > 0}/>
                                                <label className="custom-control-label" for="checkbox">Recordar por 30 días.</label>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-pill btn-accent d-table mx-auto" onClick={() => this.onClickLogin()} disabled={this.state.disabled}>Ingresar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default LoginPage;