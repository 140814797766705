import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import { validateSession } from  '../utils/functions';
import '@firebase/storage';

var config = process.env;

class AddUserPage extends Component {

	constructor(){
        super();
        this.state = {
            disabled: false
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

    checkIsValid = (input) => {
        if (input.value == null || input.value == undefined || input.value == '') {
            input.className = "form-control is-invalid";
            return true;
        } else {
            input.className = "form-control";
            return false;
        }
    }

    onClickSave = () => {
        var firstName = document.getElementById("firstName");
        var lastName = document.getElementById("lastName");
        var email = document.getElementById("email");
        var phone = document.getElementById("phone");
        var cedula = document.getElementById("cedula");
        var city = document.getElementById("city");
        var address = document.getElementById("address");
        
        var hasError = false;
        hasError = this.checkIsValid(firstName) || hasError;
        hasError = this.checkIsValid(lastName) || hasError;
        hasError = this.checkIsValid(cedula) || hasError;
        hasError = this.checkIsValid(email) || hasError;
        hasError = this.checkIsValid(phone) || hasError;
        hasError = this.checkIsValid(address) || hasError;

        if (!hasError) {
            this.setState({ disabled: true });
            var request = {
                method: 'POST',
                headers: { 
                    'access-token': localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: firstName.value,
                    lastName: lastName.value,
                    phone: phone.value,
                    email: email.value,
                    cedula: cedula.value,
                    password: "",
                    address: address.value,
                    department: city.value
                })
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'user', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                    this.setState({ disabled: false });
                    if (data.error == 1002) {
                        window.location.href = localStorage.getItem('homePageURL');
                    }
                } else {
                    window.location.href = "/users";
                }
            });
        }
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={3} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />                            
                            <div className="main-content-container container-fluid px-4 mb-4">
                                <HeaderComponent title="Dashboard" subtitle="Agregar administrador"/>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Datos personales</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-4">
                                                                    <label for="cedula">Cédula</label>
                                                                    <input type="text" className="form-control" id="cedula" placeholder="Cédula"/>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label for="firstName">Nombre</label>
                                                                    <input type="text" className="form-control" id="firstName" placeholder="Ingrese nombre/s" />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label for="lastName">Apellido</label>
                                                                    <input type="text" className="form-control" id="lastName" placeholder="Ingrese apellido/s" />
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-8">
                                                                    <label for="email">Email</label>
                                                                    <input type="email" className="form-control" id="email" placeholder="Ingrese el correo" />
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label for="phone">Teléfono</label>
                                                                    <input type="text" className="form-control" id="phone" placeholder="Ingrese el telefono" />
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label>Departamento</label>
                                                                <select id="city" className="custom-select">
                                                                    <option value="Montevideo">Montevideo</option>
                                                                    <option value="Artigas">Artigas</option>
                                                                    <option value="Canelones">Canelones</option>
                                                                    <option value="Cerro Largo">Cerro Largo</option>
                                                                    <option value="Colonia">Colonia</option>
                                                                    <option value="Durazno">Durazno</option>
                                                                    <option value="Flores">Flores</option>
                                                                    <option value="Florida">Florida</option>
                                                                    <option value="Lavalleja">Lavalleja</option>
                                                                    <option value="Maldonado">Maldonado</option>
                                                                    <option value="Paysandú">Paysandú</option>
                                                                    <option value="Rio Negro">Rio Negro</option>
                                                                    <option value="Rivera">Rivera</option>
                                                                    <option value="Rocha">Rocha</option>
                                                                    <option value="Salto">Salto</option>
                                                                    <option value="San José">San José</option>
                                                                    <option value="Soriano">Soriano</option>
                                                                    <option value="Tacuarembó">Tacuarembó</option>
                                                                    <option value="Treinta y Tres">Treinta y Tres</option>
                                                                </select>
                                                            </div>
                                                                <div className="form-group col-md-8">
                                                                    <label for="address">Address</label>
                                                                    <input type="text" className="form-control" id="address" placeholder="Ingrese la dirección" />
                                                                </div>
                                                            </div>
                                                            <div className="card-footer border-top">
                                                                <button onClick={() => this.onClickSave()} 
                                                                        type="button" 
                                                                        className="mb-2 btn btn-primary mr-2" 
                                                                        style={{float: 'right'}} 
                                                                        disabled={this.state.disabled}>
                                                                        Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddUserPage;