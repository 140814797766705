import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import InvoiceProductsComponent from '../components/InvoiceProductsComponent';
import firebase from '@firebase/app';
import '@firebase/storage';
import { validateSession } from  '../utils/functions';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

var config = process.env;

class AddInvoicePage extends Component {

	constructor(){
        super();
        this.state = {
            disabled: false,
            checked: false,
            dateSelected: null,
            role: 0,
            products: {},
            rut: 0,
            purchase: 0,
            isFinalCustomer: true,
            name: "",
            address: "",
            adenda: "",
            moneda: "UYU",
            tipo_cambio: 1,
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        const queryParams = new URLSearchParams(this.props.location.search);
        const id = queryParams.get('id');
        if (id !== null && id != undefined && id != "")
        {
            this.setState({purchase:id});
            this.fetchPurchaseData(id)
        }
        this.setState({ checked: true, role:  role});              
    }

    handlerSelectProduct = (value) => {
        this.setState({products: value});
    }

    validateData = () => {
        const { dateSelected, isFinalCustomer, rut, name, address, moneda, tipo_cambio } = this.state;
    
        // Validación de la fecha
        if (!dateSelected) {
            alert("La fecha es requerida.");
            return false;
        }
    
        // Validaciones para cuando NO es consumidor final
        if (!isFinalCustomer) {
            if (!rut) {
                alert("El RUT es requerido para no consumidores finales.");
                return false;
            }
            if (!name || name.trim() === "") {
                alert("La Razón Social es requerida para no consumidores finales.");
                return false;
            }
            if (!address || address.trim() === "") {
                alert("El Domicilio Fiscal es requerido para no consumidores finales.");
                return false;
            }
        }
    
        // Validación del tipo de cambio si la moneda es USD
        if (moneda === "USD" && (!tipo_cambio || tipo_cambio <= 1)) {
            alert("El tipo de cambio es obligatorio y debe ser mayor que 1 cuando la moneda es USD.");
            return false;
        }
    
        // Si todas las validaciones pasan
        return true;
    }

    save = async() => {
        if (!this.validateData()) {
            return;
        }

        //Convert to dic to list
        var products = [];
        for (const [key, value] of Object.entries(this.state.products)) {
            products.push(value);
        }
        
        this.setState({ disabled: true });
        var token = localStorage.getItem('token');
        var request = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'access-token': token
            },
            body: JSON.stringify({
                rut: parseInt(this.state.rut),
                purchase: parseInt(this.state.purchase),
                isFinalCustomer: this.state.isFinalCustomer,
                name: this.state.name,
                address: this.state.address != null ? this.state.address : "",
                date: new Date(this.state.dateSelected).toString(),
                products: products,
                adenda: this.state.adenda,
                currency: this.state.moneda,
                currencyExchange: parseFloat(this.state.tipo_cambio),
            })
        };


        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'invoice/custom', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                let invoiceId = data.data;
                window.open(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'invoice/file/' + invoiceId, '_blank');
                window.location = "/purchase"
            }
        });        
    }

    onChangeValue = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value.trim()});
    }

    onChangeBooleanValue= (e) => {
        const name = e.target.name;
        const value = e.target.value === "true" || e.target.value === true;
        this.setState({ [name]: value});
    }
    
    // Función de debounce
    debounce = (func, delay) => {
        let timeoutId;
        return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    onChangePurchaseValue = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (e.target.value !== null && e.target.value !== undefined && e.target.value !== "")
        {
            this.fetchPurchaseData(value)
        }
    }

    fetchPurchaseData = (value) => {        
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(`${config.REACT_APP_BASE_URL}${config.REACT_APP_VERSION}purchase/${value}`, request)
        .then(results => { return results.json(); })
        .then(data => {           
            if (data.error > 0) { 
                alert(data.message); 
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } 
            else {
                let productsMap = {}
                data.data.purchase.products.forEach(product => {
                    productsMap[product.prefix  +product.code] = product
                })
                if (data.data.purchase.ticket.rut != undefined && data.data.purchase.ticket.rut != null && data.data.purchase.ticket.rut != "") {
                    this.setState({
                        purchase: value,
                        products: productsMap,
                        dateSelected: new Date(data.data.purchase.date),
                        isFinalCustomer: false
                    })
                    this.fetchCompany(data.data.purchase.ticket.rut);
                } else {
                    this.setState({
                        purchase: value,
                        name: data.data.purchase.user.firstName,
                        address: data.data.purchase.user.address,
                        rut: data.data.purchase.user.cedula,
                        products: productsMap,
                        dateSelected: new Date(data.data.purchase.date)
                    })
                }
            }
        })
    }

    fetchCompany = (rut) => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(`${config.REACT_APP_BASE_URL}${config.REACT_APP_VERSION}user/company/${rut}`, request)
        .then(results => { return results.json(); })
        .then(data => {           
            if (data.error > 0) { 
                alert(data.message); 
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } 
            else {
                console.log(data.data)
                this.setState({
                    rut: rut, 
                    name: data.data.razonSocial,
                    address: data.data.direccionFiscal,
                })
            }
        })
    }

    renderGenericData = () => {
        return(
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom">
                        <h6 className="m-0">Datos generales</h6>
                    </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item p-3">
                    <div className="row">
                        <div className="col">
                            <div className="form-row">                            
                                <div className="form-group col-md-3">
                                    <label>Venta relacionada</label>
                                    <input autoComplete="off" className="form-control" type="text" name="purchase" defaultValue={this.state.purchase} placeholder="Numero de venta" onBlur={(e) => this.onChangePurchaseValue(e)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Consumidor Final</label>
                                    <select id="display" className="custom-select" name="isFinalCustomer" value={this.state.isFinalCustomer} onChange={(e) => this.onChangeBooleanValue(e)}>
                                        <option value="true" >Si</option>
                                        <option value="false" >No</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label for="phone">Fecha</label>
                                    <DatePicker 
                                        className="form-control" 
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.dateSelected} 
                                        onChange={(date) => this.setState({ dateSelected: date })}        
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>{this.state.isFinalCustomer ? 'CI' : 'RUT'}</label>
                                    <input autoComplete="off" className="form-control" type="text" name="rut" placeholder="Nombre/Razon social" value={this.state.rut} onChange={(e) => this.onChangeValue(e)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>{this.state.isFinalCustomer ? 'Nombre' : 'Razón Social'}</label>
                                    <input autoComplete="off" className="form-control" type="text" name="name" placeholder={this.state.isFinalCustomer ? 'Nombre' : 'Razón Social'} value={this.state.name} onChange={(e) => this.onChangeValue(e)} />
                                </div>
                                {!this.state.isFinalCustomer ? (
                                    <div className="form-group col-md-3">
                                        <label>Domicilio Fiscal</label>
                                        <input autoComplete="off" className="form-control" type="text" name="address" placeholder="Domicilio fiscal" value={this.state.address} onChange={(e) => this.onChangeValue(e)} />
                                    </div>
                                ) : ''}
                                <div className="form-group col-md-3">
                                    <label>Nota</label>
                                    <input autoComplete="off" className="form-control" type="text" name="adenda" placeholder="Adenda" value={this.state.adenda} onChange={(e) => this.onChangeValue(e)} />
                                </div>

                                <div className="form-group col-md-3">
                                    <label>Moneda</label>
                                    <select id="moneda" className="custom-select" name="moneda" value={this.state.moneda} onChange={(e) => this.onChangeValue(e)}>
                                        <option value="UYU" >Pesos</option>
                                        <option value="USD" >Dolares</option>
                                    </select>
                                </div>
                                {this.state.moneda == "USD" ? (
                                    <div className="form-group col-md-3">
                                        <label>Tipo de cambio</label>
                                        <input autoComplete="off" className="form-control" type="number" name="tipo_cambio" placeholder="Tipo de cambio" value={this.state.tipo_cambio} onChange={(e) => this.onChangeValue(e)} />
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
            </div>
        );
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={17} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent />
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '80px'}}>
                                <HeaderComponent title="Facturas" subtitle="Generar nueva factura"/>
                                { this.renderGenericData() }
                                <InvoiceProductsComponent products={this.state.products} onChangeData={ this.handlerSelectProduct }/>
                                <button disabled={this.state.disabled} type="button" class="mb-2 btn btn-primary" style={{float: "right", width: "200px", marginRight: "15px"}} onClick={() => this.save()}>Cargar</button>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddInvoicePage;